<template>
    <!--
    Диалог добавления пользователя
    -->
    <el-dialog
        class="new-voting-dialog"
        :class="{dialog_full_display: !$isMobile(), dialog_mobile: $isMobile()}"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :show-close="false"
        :modal-append-to-body="false">

        <template v-slot:title>
            <dom-title-closable value="Добавление нового пользователя" close-text="Завершить без сохранения" @close="onCancel"/>
        </template>

        <el-tabs tab-position="left" class="tab-pane-wrapper" :value="tab">

            <!--            Шаг 1 - выбор пользователя -->
            <el-tab-pane class="tab-pane" name="tab-role">
                <new-vote-tab-label slot="label" number="1" title="Выбор роли пользователя"/>

                <el-container direction="vertical" class="p-4">


                    <el-radio-group v-model="userRoleShort" @change="completedStep = null">

                        <el-row type="flex" align="middle" class="mb-5" :gutter="20">
                            <el-col :span="12">
                                <el-radio-button label="owner_fl" class="mr-2">
                                    <span>
                                        <i class="fas el-icon-fa-user blue_user_icon"></i>
                                        Собственник: физическое лицо
                                    </span>
                                    <i class="fas el-icon-fa-check-circle" :class="[userRoleShort === 'owner_fl' ? 'active_icon_button' : 'disabled_icon_button' ]"></i>
                                </el-radio-button>
                            </el-col>
                            <el-col>
                                <el-checkbox v-model="unregistredUser" :disabled="userRoleShort !== 'owner_fl'">Незарегистрированный пользователь</el-checkbox>
                            </el-col>
                        </el-row>

                        <el-row type="flex" align="middle" class="mb-5" :gutter="20">
                            <el-col :span="12">
                                <el-radio-button label="owner_org">
                                    <span>
                                        <i class="fas el-icon-fa-user-tie blue_user_icon"></i>
                                        Собственник: юридическое лицо
                                    </span>
                                    <i class="fas el-icon-fa-check-circle" :class="[userRoleShort === 'owner_org' ? 'active_icon_button' : 'disabled_icon_button' ]"></i>
                                </el-radio-button>
                            </el-col>
                            <el-col>
                                <el-checkbox v-model="unregistredOrg" :disabled="userRoleShort !== 'owner_org'">Незарегистрированный пользователь</el-checkbox>
                            </el-col>
                        </el-row>

                        <el-row type="flex" align="middle" class="mb-5" :gutter="20">
                            <el-col :span="12">
                                <el-radio-button label="manager_org">
                                    <span>
                                        <i class="fas el-icon-fa-suitcase blue_user_icon"></i>
                                        Управляющая компания
                                    </span>
                                    <i class="fas el-icon-fa-check-circle" :class="[userRoleShort === 'manager_org' ? 'active_icon_button' : 'disabled_icon_button' ]"></i>
                                </el-radio-button>
                            </el-col>
                        </el-row>

                        <el-row type="flex" align="middle" class="mb-5">
                            <el-radio-button label="validator">
                                <span>
                                    <i class="fas el-icon-fa-user-check blue_user_icon"></i>
                                    Валидатор
                                </span>
                                <i class="fas el-icon-fa-check-circle" :class="[userRoleShort === 'validator' ? 'active_icon_button' : 'disabled_icon_button' ]"></i>
                            </el-radio-button>
                        </el-row>

                        <el-row type="flex" align="middle" class="mb-5">
                            <el-radio-button label="admin">
                                <span>
                                    <i class="fas el-icon-fa-user-cog blue_user_icon"></i>
                                    Администратор системы
                                </span>
                                <i class="fas el-icon-fa-check-circle" :class="[userRoleShort === 'admin' ? 'active_icon_button' : 'disabled_icon_button' ]"></i>
                            </el-radio-button>
                        </el-row>

                    </el-radio-group>
                </el-container>

                <dom-button-accept @click="changeTab(2, 'tab-user')" :disabled="!userRoleShort" label="Сохранить и продолжить"/>

            </el-tab-pane>


            <!--            Шаг 2 - Данные пользователя -->
            <el-tab-pane class="tab-pane" name="tab-user" v-if="completedStep > 1 ">
                <new-vote-tab-label slot="label" number="2" title="Данные пользователя"/>

                <!-- Физ лицо-->
                <template v-if="isFl">
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.name" placeholder="Фамилия Имя Отчество" icon-left="fas el-icon-fa-user"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.email" placeholder="Email" icon-left="fas el-icon-fa-at" v-if="needEmail"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-datepicker v-model="newUser.birthDate" placeholder="Дата рождения" prefix-icon="fas el-icon-fa-info-circle" v-if="needBirthDate"/>
                    </el-row>
                </template>


                <!-- Юр лицо-->
                <template v-else>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.name" placeholder="Наименование организации" icon-left="fas el-icon-fa-user-tie"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.email" placeholder="Email" type="email" icon-left="fas el-icon-fa-at" v-if="needEmail"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.inn" placeholder="ИНН" type="number" icon-left="fas el-icon-fa-info-circle"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="newUser.ogrn" placeholder="ОГРН" type="number" icon-left="fas el-icon-fa-info-circle"/>
                    </el-row>
                </template>

                <dom-button-accept @click="changeTab(needProperty ? 3 : 4, needProperty ? 'tab-property' : 'tab-check')"
                                   :disabled="!formUserInfoCompleted"
                                   label="Сохранить и продолжить"/>
            </el-tab-pane>


            <!--            Шаг 3 недвижимость ползователя -->
            <el-tab-pane class="tab-pane" name="tab-property" v-if="completedStep >= 3" :disabled="!needProperty">
                <new-vote-tab-label slot="label" number="3" title="Недвижимость пользователя"/>

                <el-container class="is-vertical mb-45" v-for="(item, idx) in properties">
                    <el-row>
                        <el-col>
                            <dom-dropdown v-model="item.property"
                                          :items="allProperties"
                                          left-icon="el-icon-location"
                                          label="Выберите адрес объекта недвижимости"
                                          text-key="address" value-key="property_id" style="height: 50px"/>
                        </el-col>
                    </el-row>

                    <template v-if="needPropertyDetails">
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.name" placeholder="Наименование объекта права"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.lawType" placeholder="Вид права"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.regDocument" placeholder="Документ основания права"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <dom-dropdown v-model="item.propertyType"
                                          :items="propertyTypes"
                                          label="Выберите тип объекта"
                                          text-key="objType" value-key="value"/>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <template v-if="item.propertyType">
                                    <dom-input v-model="item.objNumber" v-if="item.propertyType.value === 'иное'" placeholder="Объект" style="margin-bottom: 10px;"/>
                                    <dom-input v-model="item.objNumber" v-else type="number" placeholder="Объект, №" style="margin-bottom: 10px;"/>
                                </template>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <dom-input v-model="item.area" placeholder="Общая площадь, м2" type="number"/>
                            </el-col>
                        </el-row>

                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-datepicker v-model="item.regDate" placeholder="Дата регистрации права" prefix-icon="fas el-icon-fa-calendar-alt"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.egrnNo" placeholder="Номер ЕГРН"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.cadNumber" placeholder="Кадастровый номер"/>
                            </el-col>
                        </el-row>
                        <el-row class="user_realty_row">
                            <el-col>
                                <dom-input v-model="item.commonNumber" placeholder="Условный номер"/>
                            </el-col>
                        </el-row>

                        <dom-button-decline v-if="properties.length > 1" @click="removeProperty(idx)" label="Удалить"/>
                    </template>

                </el-container>


                <dom-button-accept v-if="needPropertyDetails"
                                   @click="addNewUserXPropertyForOwner"
                                   style="margin-top: 20px; margin-left: 11px; height: 55px"
                                   left-icon="fas el-icon-fa-plus-circle">
                    Добавить еще один объект
                </dom-button-accept>

                <dom-button-accept @click="changeTab(4, 'tab-check')" :disabled="!formPropertiesCompleted">
                    Сохранить и продолжить
                </dom-button-accept>

            </el-tab-pane>


            <!--            Шаг 4 проверка и сохранение -->
            <el-tab-pane class="tab-pane" name="tab-check" v-if="completedStep >= 4">
                <new-vote-tab-label slot="label" number="4" title="Проверка и сохранение"/>

                <el-row style="margin: 12px 0; background: #06c98c; color: #fff" class="final_step_row" type="flex" align="middle">
                    <el-col :span="1"><i class="fas el-icon-fa-user-circle" style="font-size: 16px;"></i></el-col>
                    <el-col>
                        <span>Роль: {{ userRole | user_type_name }}</span>
                    </el-col>
                    <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px;"></i></el-col>
                </el-row>

                <template v-if="isFl">
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-info-circle blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            ФИО: <span style="font-weight: bold">{{ newUser.name }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle" v-if="needBirthDate">
                        <el-col :span="1"><i class="fas el-icon-fa-calendar-check blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            Дата рождения: <span style="font-weight: bold">{{ newUser.birthDate | ru_date_short }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle" v-if="needEmail">
                        <el-col :span="1"><i class="fas el-icon-fa-at blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            Email: <span style="font-weight: bold">{{ newUser.email }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                </template>

                <template v-else>
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-user-tie blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            Наименование: <span style="font-weight: bold">{{ newUser.name }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-at blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            Email: <span style="font-weight: bold">{{ newUser.email }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-info-circle blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            ИНН: <span style="font-weight: bold">{{ newUser.inn }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>

                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-info-circle blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            ОГРН: <span style="font-weight: bold">{{ newUser.ogrn }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                </template>

                <template v-if="needProperty && !needPropertyDetails"> <!-- Только выбор недвижимости, для менеджера-->
                    <el-row style="margin: 12px 0" class="final_step_row" type="flex" align="middle">
                        <el-col :span="1"><i class="fas el-icon-fa-map-marker-alt blue_user_icon" style="font-size: 16px;"></i></el-col>
                        <el-col>
                            <span style="font-weight: bold">{{ properties[0].property.address }}</span>
                        </el-col>
                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                    </el-row>
                </template>

                <template v-if="needProperty && needPropertyDetails">  <!-- Полные данные для собственника-->
                    <el-row type="flex" justify="center" align="center" v-for="(item, idx) in properties" :key="idx" class="question">
                        <el-card class="mb-15" style="width: 100%">
                            <el-row :gutter="20" type="flex">

                                <el-col :span="2" class="question__index mt-10">
                                    {{ idx + 1 }}
                                </el-col>
                                <el-col :span="24">
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col :span="1"><i class="fas el-icon-fa-map-marker-alt blue_user_icon" style="font-size: 16px;"></i></el-col>
                                        <el-col>
                                            <span>{{ item.property.address }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Наимнование объекта: <span style="font-weight: bold;"> {{ item.name }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Вид права: <span style="font-weight: bold;">{{ item.lawType }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Документ основания права: <span style="font-weight: bold;">{{ item.regDocument }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Адрес объекта права: <span style="font-weight: bold;">{{ item | item_address }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Дата регистрации права: <span style="font-weight: bold;">{{ item.regDate | ru_date_short }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Общая площадь объекта права: <span style="font-weight: bold;">{{ item.area }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Номер ЕГРН: <span style="font-weight: bold;">{{ item.egrnNo }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Кадастровый номер: <span style="font-weight: bold;">{{ item.cadNumber }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                    <el-row style="margin: 12px 0" class="final_step_row_inner" type="flex" align="middle">
                                        <el-col>
                                            Условный номер: <span style="font-weight: bold;">{{ item.commonNumber }}</span>
                                        </el-col>
                                        <el-col :span="1"><i class="fas el-icon-fa-cog" style="font-size: 16px; color: #AEB2BB"></i></el-col>
                                    </el-row>
                                </el-col>
                            </el-row>

                        </el-card>

                    </el-row>
                </template>

                <dom-button @click="onFinish"
                            style="margin-top: 20px; height: 55px"
                            left-icon="el-icon-circle-check"
                            right-icon="el-icon-caret-right">
                    Сохранить и завершить
                </dom-button>
            </el-tab-pane>


        </el-tabs>
    </el-dialog>

</template>

<script>

import Toggleable from "@/views/mixins/Toggleable";
import NewVoteAddQuestionDialog from "@/views/dialogs/NewVoteAddQuestionDialog";
import NewVoteAddOwnQuestionDialog from "@/views/dialogs/NewVoteAddOwnQuestionDialog";
import DomDropdown from "@/views/elements/DomDropdown";
import NewVoteTabLabel from "@/views/components/NewVoteTabLabel";
import DomDatepicker from "@/views/elements/DomDatepicker";
import DomButton from "@/views/elements/DomButton";
import DomDropdownComplex from "@/views/elements/DomDropdownComplex";
import DomTextarea from "@/views/elements/DomTextarea";
import DomHuman from "@/views/elements/DomHuman";
import DomInput from "@/views/elements/DomInput";
import {mapActions} from "vuex";
import moment from "moment";
import DomButtonDecline from "@/views/elements/DomButtonDecline";
import {mapFields} from "vuex-map-fields";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import VueScreenSize from "vue-screen-size";

export default {
    components: {
        DomButtonAccept,
        DomTitleClosable,
        DomButtonDecline,
        DomInput, NewVoteAddOwnQuestionDialog, DomHuman, DomTextarea, DomDropdownComplex, DomButton, DomDatepicker, NewVoteTabLabel, DomDropdown, NewVoteAddQuestionDialog
    },
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],

    data: () => ({
        tab: 'tab-role',
        completedStep: 1,


        userRoleShort: null,
        unregistredUser: null,
        unregistredOrg: null,

        newUser: {
            name: null,
            email: null,
            birthDate: null,
            inn: null,
            ogrn: null,
        },

        properties: [],

        title: null,
        admin_id: null,
    }),


    computed: {
        ...mapFields("user", ["me"]),
        ...mapFields("property", {allProperties: 'properties', propertyTypes: 'propertyTypes'}),

        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        userRole() {
            return this.userRoleShort === 'owner_fl' && this.unregistredUser ? 'owner_fl_unreg'
                : this.userRoleShort === 'owner_org' && this.unregistredOrg ? 'owner_org_unreg'
                    : this.userRoleShort
        },

        needEmail() {
            switch (this.userRole) {
                case 'owner_fl_unreg':
                case 'owner_org_unreg':
                    return false;
                default:
                    return true
            }
        },

        needBirthDate() {
            switch (this.userRole) {
                case 'admin':
                case 'validator':
                    return false;
                case 'owner_fl_unreg':
                  return false;
                default:
                    return true
            }
        },

        isFl() {
            return this.userRole === 'owner_fl' || this.userRole === 'owner_fl_unreg' || this.userRole === 'admin' || this.userRole === 'validator'
        },

        jurForm() {
            switch (this.userRole) {
                case 'owner_fl':
                case 'owner_fl_unreg':
                    return 'fl';

                case 'manager_org':
                case 'owner_org':
                case 'owner_org_unreg':
                    return 'org';

                default:
                    return null;
            }
        },

        uxpType() {
            switch (this.userRole) {
                // http://192.168.10.110/issues/11247#note-1
                case 'owner_fl':
                case 'owner_org':
                case 'owner_fl_unreg':
                    // case 'owner_org_unreg':
                    return 'owner';

                // case 'manager_org': return 'ruler';

                default:
                    return null;
            }
        },

        needProperty() {
            return this.uxpType != null;
        },

        needPropertyDetails() {
            return this.uxpType === 'owner';
        },

        formUserInfoCompleted() {
            const hasEmail = this.needEmail ? this.newUser.email : true;
            const hasBirthDate = this.needBirthDate ? this.newUser.birthDate : true;
            return this.isFl
                ? this.newUser.name && hasEmail && hasBirthDate
                : this.newUser.name && hasEmail && this.newUser.inn && this.newUser.ogrn
        },

        formPropertiesCompleted() {
            const filled = _(this.properties).map(item =>
                item.property.property_id &&
                item.name &&
                item.lawType &&
                item.regDocument &&
                item.objNumber &&
                item.area &&
                item.regDate &&
                item.cadNumber &&
                item.egrnNo
            )
                .filter().value();

            return filled.length === this.properties.length
        },

    },

    asyncComputed: {
        roles() {
            return this.getRoles()
        }
    },

    created() {
        this.getAllProperties();
    },

    watch: {
        userRoleShort() {
            this.properties = [];
            _.extend(this.newUser, {
                name: null,
                email: null,
                birthDate: null,
                inn: null,
                ogrn: null,
            });
        },

        tab(tab) {
            if (tab === 'tab-property') {
                console.log('tab-property')
            }
        },
    },

    methods: {
        ...mapActions("property", {getAllProperties: "getAll"}),
        ...mapActions("users", {addUser: "add"}),
        ...mapActions("meta", ["getRoles"]),
        ...mapActions("users", ["getAll"]),

        changeTab(completedStep, nextTab) {
            this.completedStep = null
            this.tab = null
            this.$nextTick(() => {
                this.completedStep = completedStep
                this.tab = nextTab
            })

            if (nextTab === 'tab-property') {
                if (this.needProperty && this.properties.length === 0) {

                    if (this.needPropertyDetails)
                        this.addNewUserXPropertyForOwner();
                    else
                        this.addNewUserXPropertyForRuler()

                } else {

                }
            }

        },

        addNewUserXPropertyForRuler() {
            this.properties.push({
                property: {},
                type: 'ruler',
                name: 'for link',
                lawType: 'for link',
                regDocument: 'for link',
                propertyType: 'for link',
                objNumber: 'for link',
                area: -1,
                regDate: moment().toISOString(true),
                commonNumber: -1,
                cadNumber: -1,
                egrnNo: -1,
            })
        },

        addNewUserXPropertyForOwner() {
            this.properties.push({
                property: {},
                type: 'owner',
                name: null,
                lawType: null,
                regDocument: null,
                propertyType: null,
                objNumber: null,
                area: null,
                regDate: null,
                commonNumber: null,
                cadNumber: null,
                egrnNo: null,
            })
        },

        removeProperty(idx) {
            this.properties.splice(idx, 1);
        },

        onCancel() {
            this.completedStep = 1;
            this.tab = '1';
            this.isActive = false;
        },

        onFinish() {

            const roleByCd = _.keyBy(this.roles, 'roleCd');

            const data = this.newUser;
            data.userRole = this.userRole;
            data.jurForm = this.jurForm;
            data.properties = this.properties;
            data.role = roleByCd[this.userRole]
            data.type = this.uxpType;

            data.properties.forEach(el => {
                if (el.propertyType.objType !== 'Иное')
                    el.address = el.propertyType.value + '. ' + el.objNumber
                else {
                    el.address = el.objNumber
                }
            });

            this.addUser(data).then(() => {
                this.getAll();
                this.$message({
                    type: 'success',
                    message: `Пользователь "${this.newUser.name}" успешно создан.`
                });
                this.isActive = false;
            })
        },

    },

    filters: {
        user_type_name(value) {
            return value === 'owner_fl' ? 'Собственник: физическое лицо'
                : value === 'owner_org' ? 'Собственник: юридическое лицо'
                    : value === 'owner_fl_unreg' ? 'Собственник: физическое лицо. Незарегистрированный пользователь'
                        : value === 'owner_org_unreg' ? 'Собственник: юридическое лицо. Незарегистрированный пользователь'
                            : value === 'manager_org' ? 'Управляющая компания'
                                : value === 'owner_manager_org' ? 'Собственник: управляющая компания'
                                    : value === 'admin' ? 'Администратор'
                                        : value === 'validator' ? 'Валидатор'
                                            : value
        }

    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.final_step_row {
    background: #fff;
    padding: 5px 5px;
    border-radius: 15px;
}

.final_step_row_inner {
    background: $dom-light-gray;
    padding: 5px 5px;
    border-radius: 15px;
}

.user_realty_row {
    margin-top: 10px;
}


.el-radio-button {

    &.is-active ::v-deep .el-radio-button__inner {
        background: #fff;
        color: #606266;
    }

    ::v-deep .el-radio-button__inner {
        width: 350px;
        display: flex;
        justify-content: space-between;
    }
}

.blue_user_icon {
    color: $dom-light-blue;
    margin-right: 10px;
}

.active_icon_button {
    color: $dom-green;
    margin-left: 12px;
}

.disabled_icon_button {
    color: #fff;
    margin-left: 12px;
}

.title {
    font-size: 22px;
    font-weight: bold;
}

.close-icon {
    font-size: 24px;
}


.el-dialog__wrapper.warning-dialog {
    ::v-deep .el-dialog__header {
        background: $dom-light-gray;
        height: 34px;
    }

    ::v-deep .el-dialog__title {
        font-size: 26px;
    }
}

.question {
    ::v-deep .question__index {
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: $dom-white;
        background: $dom-green;
        font-size: 20px;
        border-radius: 50%;
        text-align: center;
    }

    .question__files {
        margin: 15px 0 0 0;
        padding: 0 0 0 35px;
        min-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: $dom-light-blue;
    }

    .question__files:last-child {
        margin-bottom: 0;
    }

    .question__files_delete {
        color: $dom-gray;
        font-size: 16px;
        margin-left: 5px;
    }

    .question__files_delete:hover {
        cursor: pointer;
    }


    .question__delete {
        color: $dom-red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $dom-red;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
    }

    .question__delete:hover {
        cursor: pointer;
    }

    .question__title {
        background: $dom-light-gray;
        border-radius: 3px;
        padding: 7px;
        font-size: 16px;

        &.el-textarea {
            padding: 0;

            ::v-deep textarea {
                padding-left: 30px;
            }

            & + i {
                position: absolute;
                left: 45px;
                top: 7px;
            }
        }
    }

    .question__upload_button {
        padding: 5px 0;
        vertical-align: center;
        text-align: center;
        color: $dom-light-blue;
        border-radius: 5px;
        border: 2px solid $dom-light-blue;

        ::v-deep i {
            font-size: 18px;
        }

        ::v-deep span {
            margin: 0 10px;
            line-height: 20px;
            font-size: 14px;
        }


    }

    .question__title_icon {
        color: $dom-light-blue;
        padding-right: 10px;
        font-size: 20px;
    }
}

.dialog_full_display {
    max-height: 70vh;
    width: 80%;
    margin: 35px auto;
}
.dialog_mobile {
    width: 100%;
}

.new-voting-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        width: 100%;
        margin: 0 auto !important;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }
}

.tab-pane {
    background-color: $dom-light-gray;
    height: 100%;
}

.tab-pane-wrapper {
    background-color: $dom-light-gray;
    height: 100%;

    ::v-deep .el-tabs__item {
        background-color: $dom-light-gray;
        padding: 0;
        height: 80px;
        //width: 250px;
    }

    ::v-deep .el-tabs__content {
        max-height: 100%;
        padding: 20px;
    }

    ::v-deep .is-disabled {
        opacity: .2;
    }
}


</style>
