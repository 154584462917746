<template>
    <!--
    Компонент заголовка сайта
    -->

    <el-container class="header">

        <div class="logo" :class="{collapsed: !isMenuOpenedGetter}">
            <i class="fas fa-bars burger-menu" @click="toggleMenu"></i>
            <span v-if="isMenuOpenedGetter">ЖИЛДОМКОМ</span>
        </div>

        <el-container class="menu">
            <el-row type="flex" align="middle" justify="space-between" class="header__title_row">
                <el-col class="page-title">{{ page_title }}</el-col>

                <template v-if="route==='users'">
                    <el-col class="header__add_button_realty">
                        <el-button icon="fas el-icon-fa-plus" circle size="mini" @click="isNewUserDialogVisible = true"></el-button>
                    </el-col>
                    <el-col class="header__search_realty">
                        <dom-search v-model="userSearch"/>
                    </el-col>
                </template>
                <template v-if="route==='properties'">
                    <el-col class="header__add_button_realty" v-if="$is_admin()">
                        <el-button icon="fas el-icon-fa-plus" circle size="mini" @click="isAddNewRealtyDialogVisible = true"></el-button>
                    </el-col>
                    <el-col class="header__search_realty">
                        <dom-search v-model="propertySearch"/>
                    </el-col>
                </template>

                <el-col class="right-elements no-wrap">
                    <span class="user-name">{{me.name}}</span>
                    <el-button type="info" circle @click="logout">
                        <i class="fas el-icon-fa-sign-out-alt " style="font-size: 140%"></i>
                    </el-button>
                </el-col>
            </el-row>
        </el-container>
        <add-new-user-dialog v-if="isNewUserDialogVisible" v-model="isNewUserDialogVisible"/>
        <add-realty-dialog v-if="isAddRealtyDialogVisible" v-model="isAddRealtyDialogVisible"/>
        <add-new-realty-dialog v-if="isAddNewRealtyDialogVisible" v-model="isAddNewRealtyDialogVisible"/>


    </el-container>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import DomSearch from "@/views/elements/DomSearch";
import AddNewUserDialog from "@/views/dialogs/AddNewUserDialog";
import AddRealtyDialog from "@/views/dialogs/AddRealtyDialog";
import AddNewRealtyDialog from "@/views/dialogs/AddNewRealtyDialog";
import {mapFields} from "vuex-map-fields";

export default {
    components: {DomSearch, AddNewUserDialog, AddRealtyDialog, AddNewRealtyDialog},
    data() {
        return {
            isNewUserDialogVisible: false,
            isAddRealtyDialogVisible: false,
            isAddNewRealtyDialogVisible: false,
        }
    },
    computed: {
        ...mapGetters("mainPage", ["page_title"]),
        ...mapGetters("menu", ["isMenuOpenedGetter"]),
        ...mapGetters("user", ["me"]),
        ...mapFields("users", {userSearch: "search"}),
        ...mapFields("property", {propertySearch: "search"}),
        route() {
            return this.$route.name
        }
    },

    methods: {
        ...mapActions("login", ["clear_code"]),
        ...mapMutations("menu", ["setMenu"]),

        logout() {
            this.clear_code().then(() => location.reload())
        },

        toggleMenu() {
            this.setMenu(!this.isMenuOpenedGetter);
        },
    },

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

.header {
    height: 100%;
    align-items: center;
    padding: 0;


}


.menu {
    padding: 0 20px 0 40px;

    .header__title_row {
        width: 100%;

        .page-title {
            font-size: 14pt;
            min-width: 100px;
            display: flex;
            flex-wrap: nowrap;
        }

        .header__add_button_realty {
            text-align: end;
            border-collapse: collapse;
            padding-right: 10px;

            button {
                height: 38px;
                width: 38px;
                border-collapse: collapse;
                background: $dom-light-blue;
            }

            ::v-deep i {
                background: $dom-white;
                color: $dom-light-blue;
                border-radius: 50%;
                padding: 4px;
            }
        }

        .header__search_realty {
            text-align: left;
        }

        .right-elements {
            text-align: end;
        }

    }
}

.user-name {
    margin: 0 20px;
}

.no-wrap {
    white-space: nowrap;
}


.logo {
    width: $sidebar-width;
    height: 100%;
    background: $dom-light-gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.collapsed{
        width: 90px;
    }

    .burger-menu{
        font-size: 30px;
        padding: 0 25px;
        cursor: pointer;
    }

    span {
        font-weight: bold;
        font-family: sans-serif;
        font-size: 16pt;
    }
}

</style>
